.tooltip-inner {
  background-color: white !important;
  color: #636363 !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: 0px 0px 8px #009fe333 !important;
  font-size: 14px !important;
  width: auto !important;
  min-width: 300px !important;
  padding: 15px 30px !important;
  text-align: left !important;
  margin-bottom: 5px !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip.arrow {
  display: none !important;
}

.tooltip.custom-tooltip--small .tooltip-inner {
  min-width: 100px !important;
  padding: 10px 20px !important;
}

.tooltip.custom-tooltip--option .tooltip-inner {
  min-width: auto !important;
  padding: 5px 10px !important;
}

.tooltip.custom-tooltip {
  opacity: 1 !important;
}

.bs-tooltip-left > .arrow::after {
  display: none;
}
.bs-tooltip-left .arrow::before {
  display: none;
}

.bs-tooltip-right .arrow::before {
  display: none;
}
.bs-tooltip-top .arrow::before {
  display: none;
}
.custom-tooltip .bs-tooltip-bottom .arrow::before {
  display: none;
}
.bs-tooltip-right > .arrow::after {
  display: none;
}

.bs-tooltip-top > .arrow::after {
  display: none;
}
.bs-tooltip-bottom > .arrow::after {
  display: none;
}

.bs-tooltip-bottom-left > .arrow::after {
  display: none;
}
.bs-tooltip-bottom-left > .arrow::before {
  display: none;
}

.bs-tooltip-bottom-right > .arrow::after {
  display: none;
}
.bs-tooltip-bottom-right > .arrow::before {
  display: none;
}

.bs-tooltip-top-left > .arrow::after {
  display: none;
}
.bs-tooltip-top-left > .arrow::before {
  display: none;
}

.bs-tooltip-top-right > .arrow::after {
  display: none;
}
.bs-tooltip-top-right > .arrow::before {
  display: none;
}
