/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./tooltips.css";
@import "./app/styles/_globals.css";

/* @import "~material-icons/iconfont/material-icons.css"; */

@font-face {
  font-family: "Clear Sans";
  src: url("assets/fonts/clear-sans/ClearSans-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Clear Sans";
  src: url("assets/fonts/clear-sans/ClearSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Clear Sans";
  src: url("assets/fonts/clear-sans/ClearSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Clear Sans";
  src: url("assets/fonts/clear-sans/ClearSans-MediumItalic.ttf")
    format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Clear Sans";
  src: url("assets/fonts/clear-sans/ClearSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Clear Sans";
  src: url("assets/fonts/clear-sans/ClearSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Clear Sans";
  src: url("assets/fonts/clear-sans/ClearSans-BoldItalic.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
}

* {
  font-family: "Clear Sans", "Roboto", sans-serif;
}

.perforation-input-wrapper .debounce-input {
  padding: 0 10px !important;
}

/* START STYLES NGX SLIDER */

.custom-ngx-slider .ngx-slider-span {
  font-size: 12px !important;
}
.custom-ngx-slider .ngx-slider-pointer {
  width: 20px !important;
  height: 20px !important;
  top: auto !important;
  bottom: -7px !important;
  background-color: #009fe3 !important;
  outline: none !important;
}

.custom-ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-ngx-slider .ngx-slider-bar {
  height: 2px !important;
  background: #cfcfcf;
  border-radius: 4px !important;
}

.custom-ngx-slider .ngx-slider-selection {
  transform: translateY(-1px) !important;
  height: 4px !important;
  background-color: #009fe3 !important;
}

.custom-ngx-slider:hover .ngx-slider-pointer {
  background-color: #1980b9 !important;
}

.custom-ngx-slider:hover .ngx-slider-selection {
  background-color: #1980b9 !important;
}

/* END STYLES NGX SLIDER */

/* START STYLES FOR DIALOG TO CREATE POSITION */

.modal-dialog {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.dialog-modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 50px;
  margin-bottom: 25px;
}

.dialog-modal-header-content {
  display: flex;
  align-items: flex-start;
}

.dialog-modal-title-container {
  margin: 0;
}

.dialog-modal-title-primary {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.5;
  color: #2e2e2e;
}

.dialog-modal-title-secondary {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #2e2e2e;
}

.dialog-modal-window {
  border: 1px solid #009fe3;
  padding: 5px 10px;
  line-height: 1.5;
  font-weight: 300;
  font-size: 14px;
  color: #040404;
  margin-right: 50px;
  max-width: 450px;
  margin-left: 30px;
}

.dialog-modal-body {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  align-items: center;
  /* MOZILLA SCROLL STYLES */
  scrollbar-width: thin;
  scrollbar-color: #009fe3 #636363;
}

/* CHROME SCROLL STYLES */
.dialog-modal-body::-webkit-scrollbar {
  width: 20px;
}

.dialog-modal-body::-webkit-scrollbar-track {
  background: #636363;
  border-left: 9px solid #ffffff;
  border-right: 9px solid #ffffff;
}

.dialog-modal-body::-webkit-scrollbar-thumb {
  background: #009fe3;
  border-left: 7px solid #ffffff;
  border-right: 7px solid #ffffff;
  border-radius: 8px;
}

.dialog-modal-body input {
  outline: none;
  border: 1px solid #cfcfcf;
  padding: 3px 6px;
  box-sizing: border-box;
  font-size: 14px;
  color: #2e2e2e;
}

.dialog-modal-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 20px 50px 30px 50px;
}

.dialog-modal-link {
  display: block;
  font-size: 17px;
  text-decoration: underline;
  font-weight: 400;
  color: #009fe3;
  transition: 0.2s;
  cursor: pointer;
}

.dialog-modal-link:hover {
  color: #1980b9;
}

.btn-close {
  position: absolute;
  right: 20px;
  top: 15px !important;
  width: 0.4rem !important;
  height: 0.4rem !important;
  font-size: 1.25rem !important;
  opacity: 0.5;
}

.btn-close:hover {
  opacity: 1;
}

.btn-create {
  width: 130px;
}

/* END STYLES FOR DIALOG TO CREATE POSITION */

/* START STYLES FOR SMALL DIALOG */

.dialog-modal-header--small {
  padding: 20px 30px 10px 30px;
  margin-bottom: 0;
}

.dialog-modal-body--small {
  padding: 0 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  justify-content: flex-start !important;
}

.dialog-modal-footer--small {
  padding: 10px 30px 20px 30px;
}

.dialog-modal-footer--one-btn {
  justify-content: flex-end;
}

.dialog-modal-title--small {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.dialog-modal-btn--small {
  height: 40px;
  padding: 0 25px !important;
  font-size: 15px !important;
}

.dialog-modal-title-img {
  margin-right: 9px;
}

.dialog-modal-description--small {
  color: #040404;
  line-height: 1.5;
  margin: 0;
  font-size: 15px;
}

/* END STYLES FOR SMALL DIALOG */

/* START STYLES FOR CASSETTE TEMPLATES */

.casette-modal-header {
  margin-bottom: 0;
}

.casette-modal-body {
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
}

.casette-configuration-section {
  display: flex;
  flex-direction: column;
  width: calc(100% - 650px);
}

.bends-toggles-wrapper {
  margin-top: 20px;
}

.orientation-cassette-wrapper {
  margin-bottom: 20px;
}

.orientation-cassette-desc {
  display: flex;
  flex-direction: column;
}

.orientation-cassette-desc-first {
  font-size: 14px;
  color: #040404;
  font-weight: 400;
  line-height: 1.5;
}

.orientation-cassette-desc-second {
  font-size: 14px;
  color: #040404;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 10px;
}

.bending-switch-btns {
  display: flex;
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.casette-modal-body input {
  outline: none !important;
  border: 1px solid #cfcfcf !important;
  padding: 3px 6px !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
  color: #2e2e2e !important;
  width: 80px !important;
}

.bending-switch-button {
  cursor: pointer;
  text-align: center;
  width: 60px;
  height: 60px;
}

.bending-switch-button:hover {
  border: 2px solid #009fe3;
}

.bending-switch-button--active {
  background-color: #009fe3;
}

.bending-switch-button--active .icon-up,
.bending-switch-button--active .icon-down {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(18%)
    hue-rotate(314deg) brightness(106%) contrast(104%);
}

.icon-down,
.icon-up {
  width: 100%;
  height: 100%;
}

.icon-down.active,
.icon-up.active {
  border: 1px solid #009fe3;
}

/* END STYLES FOR CASSETTE TEMPLATES */

.mat-ripple-element {
  display: none !important;
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: #009fe3;
}

.mat-mdc-slide-toggle .mdc-switch__track::after {
  background-color: #1980b9;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle .mat-slide-toggle-bar {
  background-color: #f8f8f8;
  border: 1px solid #cfcfcf;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle .mat-slide-toggle-thumb {
  background-color: #009fe3;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #1980b9;
  border: 1px solid #1980b9;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #009fe3;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle .mat-slide-toggle-content {
  font-size: 14px;
  font-weight: 400;
  color: #636363;
}

.mat-mdc-slide-toggle .mdc-label span {
  font-size: 14px;
  font-weight: 500;
  color: #636363;
}

.btn {
  transition: 0.2s;
  border-radius: 0 !important;
}
.toggle-btn {
  display: inline-block;
  outline: 0;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  user-select: none;
  background: #fbfbfb;
  padding: 0.1em;
  transition: all 0.2s ease;
  border: 1px solid #009fe3;
}

/*override style for material checkbox --start*/

.base-checkbox .mdc-label {
  font-size: 14px;
  font-weight: 500;
  color: #636363;
}

.base-checkbox .mdc-checkbox__ripple {
  display: none !important;
}

.base-checkbox .mdc-checkbox {
  width: 18px;
  height: 18px;
}

.base-checkbox .mdc-checkbox__background {
  border-radius: 4px !important;
  border: 1px solid #757575 !important;
}

.base-checkbox .mdc-checkbox__checkmark-path {
  stroke: #009fe3 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.base-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: transparent !important;
  border: 1px solid #009fe3 !important;
}

.base-checkbox .text {
  color: #636363;
  font-size: 14px;
  font-weight: 400;
}

.base-checkbox .mat-mdc-checkbox-touch-target {
  display: none !important;
}

/*override style for material checkbox --end*/

button:hover,
button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  background-color: #009fe3 !important;
  border-color: #009fe3 !important;
}

.btn-primary:hover {
  background-color: #1980b9 !important;
  border-color: #1980b9 !important;
}

.btn-primary:disabled {
  opacity: 1 !important;
  background-color: #0000001f !important;
  border: 0 !important;
  color: #00000042 !important;
  cursor: not-allowed !important;
}

.btn-primary:disabled:hover {
  opacity: 1 !important;
  background-color: #0000001f !important;
  border: 0 !important;
  color: #00000042 !important;
  cursor: not-allowed !important;
}

.btn-outline-primary {
  color: #009fe3 !important;
  border-color: #009fe3 !important;
}

.btn-outline-primary:hover {
  background-color: #009fe3 !important;
  color: white !important;
}

.btn-black {
  background: black !important;
  color: white !important;
}

.btn-black:hover {
  background: #636363 !important;
  color: white !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-label {
  margin-bottom: 0px !important;
}

/* CAROUSEL STYLES START */

.user-templates-carousel {
  width: 100%;
}

.user-templates-carousel .ui-carousel {
  padding: 0;
  margin: 0;
  border: none;
}

.user-templates-carousel .ui-carousel .ui-carousel-next-button {
  position: absolute;
  top: 50%;
  right: 0;
  height: 18px;
  width: 18px;
  background-image: url("assets/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  filter: invert(63%) sepia(86%) saturate(5546%) hue-rotate(171deg)
    brightness(101%) contrast(101%);
  transform: rotate(-90deg);
  z-index: 10;
}

.user-templates-carousel .ui-carousel .ui-carousel-prev-button {
  position: absolute;
  width: 18px;
  height: 18px;
  background-image: url("assets/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  filter: invert(63%) sepia(86%) saturate(5546%) hue-rotate(171deg)
    brightness(101%) contrast(101%);
  transform: rotate(90deg);
  top: 50%;
  left: 0;
}

.user-templates-carousel .ui-carousel .ui-carousel-viewport {
  margin: 0 30px;
  border: none;
  padding: 0;
  background-color: transparent;
}

.user-templates-carousel .ui-carousel .ui-carousel-header {
  background-color: transparent;
  padding: 0;
  border: none;
  margin: 0 30px;
}

.user-templates-carousel .ui-carousel .ui-carousel-items {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
}

.user-templates-carousel .ui-carousel .ui-carousel-items .ui-carousel-item {
  border: none !important;
  margin: 0;
  padding: 0;
}

.user-templates-carousel .ui-carousel-header-title {
  display: none;
}

.user-templates-carousel .ui-carousel .ui-carousel-page-links {
  display: none;
}

.user-templates-carousel
  .ui-carousel
  .ui-carousel-button.ui-carousel-next-button:before {
  display: none;
  content: "";
}

.user-templates-carousel .ui-carousel-button.ui-carousel-prev-button:before {
  display: none;
  content: "";
}

/* CAROUSEL STYLES END */

.list .mat-mdc-list {
  display: grid;
  font-size: 14px;
  color: #636363;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #009fe3;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-outer-circle {
  border-width: 1px !important;
  border-color: #cfcfcf !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #cfcfcf !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.7) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button .mat-radio-container {
  margin-right: 5px;
}

.mat-mdc-radio-button {
  margin-right: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-label-content {
  font-weight: 400;
  color: #636363;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-checked .mat-radio-label-content {
  color: #2e2e2e;
}

.hand {
  cursor: grabbing;
}

html,
body,
.no-print {
  height: 100%;
}

.modal-lg {
  max-width: 1024px !important;
}
.flex {
  display: flex;
}

.toggle-btn::after {
  left: 0;
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 90%;

  transition: all 0.16s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.16s ease, margin 0.16s ease;
  border: 1px solid black;
}
.toggle-btn.toggle-btn-on::after {
  left: 50%;
}
.toggle-btn.toggle-btn-on {
  background: #009fe3;
}
.toggle-btn.toggle-btn-on::after {
  background: #fff;
}
.toggle-btn.toggle-btn-off::after {
  background: #a1a1a1;
}
.toggle-btn.toggle-btn-on:active {
  box-shadow: none;
}
.toggle-btn.toggle-btn-on:active::after {
  margin-left: -0.8em;
}
.toggle-btn:active::after {
  padding-right: 0.8em;
  background: #fff;
}

.custom-modal-backdrop {
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.custom-modal-backdrop--show-navigation {
  top: 90px !important;
}

.custom-modal-window {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 2000 !important;
  /* MOZILLA SCROLL STYLES */
  scrollbar-width: thin;
  scrollbar-color: #009fe3 #636363;
}

.custom-modal-window--show-navigation {
  top: 90px !important;
}

/* FOR MOZILLA FIREFOX */
.custom-modal-window {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

/* FOR GOOGLE CHROME */
@supports (
  (-webkit-backdrop-filter: blur(35px)) or (backdrop-filter: blur(35px))
) {
  .custom-modal-window {
    background: rgba(0, 0, 0, 0.03) !important;
    -webkit-backdrop-filter: blur(35px) !important;
    backdrop-filter: blur(35px) !important;
  }
}

/* CHROME SCROLL STYLES */
.custom-modal-window::-webkit-scrollbar {
  width: 20px;
}

.custom-modal-window::-webkit-scrollbar-track {
  background: #636363;
  border-left: 9px solid #ffffff;
  border-right: 9px solid #ffffff;
}

.custom-modal-window::-webkit-scrollbar-thumb {
  background: #009fe3;
  border-left: 7px solid #ffffff;
  border-right: 7px solid #ffffff;
  border-radius: 8px;
}

.modal-dialog {
  margin: 0 auto !important;
}

.modal-content {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  border: none !important;
}

.modal-dialog::before {
  display: none !important;
  content: "" !important;
}

.mdc-switch:enabled .mdc-switch__track::before {
  background-color: #f8f8f8 !important;
  border: 1px solid #cfcfcf !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background-color: #1980b9 !important;
}

.mat-mdc-slide-toggle .mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch__ripple {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch__shadow {
  background-color: #009fe3;
}

.mat-mdc-slide-toggle .mdc-elevation-overlay:hover {
  background-color: #009fe3;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #009fe3;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #1980b9;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-thumb {
  background-color: #009fe3;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: #009fe3;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-thumb {
  position: absolute;
  right: -15px;
  bottom: -15px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-radius: 50%;
  transform: scale(0.7);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    border-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-disabled .mat-slider-thumb {
  background-color: #bdbdbd !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-slider-disabled .mat-slider-track-fill {
  background-color: #bcbcbc !important;
}

/* ZOOM BTNS STYLES START */

.zoom-btn {
  border: none;
  padding: 0;
  cursor: pointer;
}

.fit-zoom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: #e3e3e3;
  border: none;
  color: #040404;
  font-weight: 300;
  margin-left: 15px;
  cursor: pointer;
}

.margin-left-5 {
  margin-left: 5px;
}

/* ZOOM BTNS STYLES END */
